var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";
import Theme from "../components/react-components/Styles/Theme";
import { Container, FiltersContainer, ContentContainer } from "../components/react-components/shared-components/Ui/Grid/Grid";
import { FilterCard } from "../components/react-components/shared-components/SideFilter/FilterCard";
import { Views } from "../components/react-components/bonscoups-components/Views";
import { CleanFilters } from "../components/react-components/bonscoups-components/CleanFilters";
import { Title } from "../components/react-components/shared-components/Title/Title";
import { SearchBar } from "../components/react-components/bonscoups-components/SearchBar";
import { InfoBar } from "../components/react-components/bonscoups-components/InfoBar";
import { BonsCoupsProvider } from "./Context/BonsCoupsContext";
import { PageLoading } from "./../components/react-components/shared-components/Loading/PageLoading";
import { Pagination } from "../components/react-components/bonscoups-components/Pagination";
import GlobalStyles from "../components/react-components/Styles/Global";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import { useTranslation } from 'react-i18next';
import { Filters } from "../components/react-components/bonscoups-components/Filters";
export var BonsCoups = function () {
    var t = useTranslation(["advanced-search"]).t;
    var _a = __read(React.useState("en"), 2), langCode = _a[0], setLangCode = _a[1];
    React.useEffect(function () {
        var _a;
        var code = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        if ((code === null || code === void 0 ? void 0 : code.length) === 2) {
            setLangCode(code);
            i18next.changeLanguage(code);
        }
    }, []);
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLoading, {}) }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(BonsCoupsProvider, { children: _jsxs(ThemeProvider, __assign({ theme: Theme }, { children: [_jsx(GlobalStyles, {}), _jsx("div", __assign({ className: "pg-bons-coups" }, { children: _jsxs(Container, { children: [_jsxs(FiltersContainer, { children: [_jsx(SearchBar, {}), _jsxs(FilterCard, { children: [_jsx(CleanFilters, {}), _jsx(Filters, {})] })] }), _jsxs(ContentContainer, { children: [_jsx(Title, { title: t(langCode === "en" ? "Special Mentions" : "Bons coups") }), _jsx(InfoBar, {}), _jsx(Views, {}), _jsx(Pagination, {})] })] }) }))] })) }) })) })));
};
var domNode = document.querySelector("#bons-coups");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(BonsCoups, {}));
}
