var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import BonsCoupsContext from "../../../application/Context/BonsCoupsContext";
import { motion } from "framer-motion";
import { BonsCoupsCard } from "../advanced-search-components/Cards/BonsCoupsCard";
import { Loading } from "../shared-components/Loading/Loading";
export var Views = function () {
    var t = useTranslation(["common"]).t;
    var _a = useContext(BonsCoupsContext), bonsCoups = _a.bonsCoups, bonsCoupsIsLoading = _a.bonsCoupsIsLoading;
    React.useEffect(function () {
    }, []);
    return bonsCoupsIsLoading ? (_jsx(Loading, {})) : bonsCoups.length > 0 ? (_jsx(React.Fragment, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: bonsCoups.map(function (item, i) { return (_jsx(BonsCoupsCard, { item: item, noimage: false }, i)); }) })) })) : (_jsx("div", { children: t("No result") }));
};
